.services_detail_image{
    .teblet{
        display: none;
    }
    .mobile{
        display: none;
    }
    @media(max-width:991px){
        .desktop{
            display: none;
        }
        .mobile{
            display: none;
        }
    }

    @media(max-width:767px){
        .desktop{
            display: none;
        }
        .tablet{
            display: none;
        }
    }

    img{
        width: 100%;
        display: block;
    }
}

.services_list{

    @include media-breakpoint-down(lg) {
        padding: 50px  0!important;
    }

    .service_list_desc{
        @include media-breakpoint-down(lg) {
            margin-bottom: 0 !important;
        }
    }
}