//
// your custom scss goes here
//

.sketchfab-embed-wrapper {
    height: 100%;
    z-index: 100;
    // transform: translate(-25px);
}

.ghost-underline {
    position: relative;
    z-index: 100;
    color: $black;
    transition: background-size 0.6s ease-out;
    background: linear-gradient(#ff1a769d,
            #ff1a7677) no-repeat left 87%/0 30%;

}

.ghost-underline:hover {
    background-size: 97% 30%;
}

.sketchfab-description {
    margin-right: 10%
}

// styles.scss
$svg-width: 100%; // Set the SVG width to 100% of the viewport width
$svg-height: 100%; // Set the SVG height to 100% of the viewport height

#full-page-svg {
    width: $svg-width;
    height: $svg-height;
}

.medium-title {
    font-size: 2rem;
}

.selector-block {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

@media(max-width: 576px) {
    .hide-on-mobile {
      display: none;
      }

    .padding-top-mobile {
        padding-top: 0px !important;
    }
    
    .padding-index {
        margin-bottom: -35px;
        padding-bottom: 0 !important;
    }

    .btn-primary  {
        height: 40px !important;
    }

    .btn_border {
        height: 40px !important;
    }

    .sketchfab-embed-wrapper {
        height: 60vh;
    }

    }

  .grecaptcha-badge { 
    visibility: hidden !important;
    }

