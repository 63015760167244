//
// sidebar
//
.offcanvas-end {
  width: 499px !important;
  padding: rem(40);
  border: 0;
  overflow-y: auto;
  overflow-x: hidden;

  @include media-breakpoint-up(lg) {
    max-width: 50vw;
    width: 100% !important;
    
  }

  @include media-breakpoint-up(xl) {
    max-width: 35vw;
    width: 100% !important;
    
  }

  @include media-breakpoint-down(md) {
    max-width: 100%;
    width: 100% !important;
  }

  .offcanvas-body {
    padding: 0;
    overflow: visible;
  }
}

.offcanvas-wrap {
  transition: transform 0.4s ease-in-out;
}

.offcanvas-push {
  .offcanvas-wrap {
    transform: translateX(rem(-100));
  }
}

.offcanvas-header {
  margin: 0 0 rem(32) 0;
  padding: 0 0 rem(32) 0;
  border-bottom: $border-width solid $border-color;
}

.offcanvas-title {
  font-size: $font-size-lg;
}

.headroom{
  .modal-image-main{
    .banner_main{
      flex-wrap: nowrap;
      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }
      .col-lg-6{
        @include media-breakpoint-down(lg) {
          padding: 60px !important;
        }
        @include media-breakpoint-down(md) {
          padding: 40px 24px !important;
        }
      }
    }
    .right_banner {
    
      right: 0;
      height: 100%;
      width: calc(100% + (1320px - 100%) / 2);
      @media(max-width:1600px) {
        width: calc(100% + (200px - 100%) / 2);
      }

      @include media-breakpoint-down(lg) {
        width: 100%;
      }
      @include media-breakpoint-down(md) {
          height: 360px;
      }
    }
  }
  
  .sketchfab-description{
    margin-right: 0 !important;
    margin-left:0 !important;
    @include media-breakpoint-down(lg) {
      margin-left: 0 !important;
      margin-bottom: 0 !important;
    }

    h1{
        @include media-breakpoint-down(xl) {
            font-size: 37px;
        }

        @include media-breakpoint-down(md) {
          font-size: 34px;
          margin-bottom: 16px;
      }
    }

    p{
      @include media-breakpoint-down(lg) {
        margin-bottom: 22px;
      }
    }
  }
  

  .navbar{
    .container{
      justify-content: space-between !important;
      @include media-breakpoint-down(lg) {
        padding: 0 60px;
      }
      @include media-breakpoint-down(md) {
        padding: 0 24px;
      }
    }
  }
}

.offcanvas.offcanvas-end{
  transform: translateX(100%);
  right:0;
  left: auto;
}

.offcanvas-push{
  .offcanvas-wrap{
    transform: translateX(0) !important;
  }
}

.offcanvas-backdrop{
  background: rgba(0, 0, 0, 0.40) !important;
  backdrop-filter: blur(6.5px) !important;
  opacity: 1 !important
}

.container, .container-sm, .container-md{
  @include media-breakpoint-down(lg){
    max-width: 100% !important;
    width: 100%;
  }
}


