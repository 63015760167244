//
// navbar
//

.navbar {
  padding: rem(32) 0;

  @include media-breakpoint-down(lg) {
    padding: 0 0 0;
  }


  .container-fluid{
    width: 100%;
    @include media-breakpoint-up(lg) {
     
      padding: 0 60px;
    }
  }
}

.navbar-sticky {
  transform: translateY(0%);
  transition: all 0.4s ease-in-out;
  position: fixed;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  opacity: 1;
  z-index: 1040;
  @include media-breakpoint-down(lg) {
    position: static;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  
  
}

// header interaction
.headroom--unpinned,
.offcanvas-push {
  .navbar-sticky {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.headroom--not-top {
  .navbar-sticky {
    padding: rem(16) 0;

    &.navbar-dark {
      background:  $white;
    }

    &.navbar-light {
      background: none;
    }
  }
}

// dark
.navbar-dark {
  .navbar-nav {
    .nav-link {
      color: $black;
      
      
    }
  }
}

// light
.navbar-light {
  .navbar-nav {
    .nav-link {
      @include media-breakpoint-up(lg) {
        color: $white;
      }

      @include media-breakpoint-down(lg) {
        color: $black;
        .bi-instagram{
          position: relative;
          top: 2px;
        }
      }
    }
  }
}

// uncolapsed on small screen
.navbar-active {
  .navbar-dark {
    background: $black;
  }

  .navbar-light {
    background: $white;
    
  }
}

// navbar navigation
.navbar-nav {
  margin-top: rem(16);

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  .nav-link {
    @include nofocus;

    @include media-breakpoint-up(lg) {
      padding-left: rem(16) !important;
      padding-right: rem(16) !important;
    }
  }

  .nav-icon {
    @include nofocus;
    display: flex;
    width: rem(48);
    height: rem(48);
    padding: 0;
    align-items: center;
    justify-content: center;
    font-size: rem(20);

    .bi-list {
      font-size: rem(22);
      margin-top: 2px;
    }
  }
}

.navbar-nav-secondary {
  margin-top: 0;
  flex-direction: row;
  align-items: center;

  @include media-breakpoint-down(lg) {
    .nav-link {
      padding-left: rem(16);
      padding-right: rem(32);
    }
  }
}

.account-collapse {
  flex-basis: 100%;
  flex-grow: 1;
}

// brand
.navbar-brand {
  img {
    width: auto;
    max-height: rem(90);
    
    
  }

  @include media-breakpoint-down(lg) {
    margin: 0;
    -webkit-filter: invert(100%);
    filter: invert(100%);
    padding: 0;
  }
}

@media only screen and (max-width: 576px) {
  .navbar-brand {
    img {
      width: auto;
      max-height: rem(60);
    }

    @include media-breakpoint-down(sm) {
      margin: 0;
    }
  }
}


// button
.navbar-dark {
  .btn-outline-white {
    border-color: rgba($white, 0.25);
  }
}

.navbar-light {
  .navbar-menu {
    .nav-link {
      color: $black;
      border-color: $border-color;

      &:hover,
      &[aria-expanded="true"] {
        background: $border-color;
        border-color: transparent;
      }
    }
  }
}

.navbar-active {
  overflow: hidden;

  .navbar {
    max-height: 100vh;
    overflow-y: scroll;
  }

  &.headroom--unpinned {
    .navbar-sticky {
      padding: rem(32) 0;
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.appoint_ment{
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: $black;
  margin-top: 16px;
  display: inline-block;
  text-decoration: underline;
  @include media-breakpoint-down(lg) {
    margin-top: 0 !important;
  }
}

.non_underline_link{
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: $black;
  margin-top: 16px;
  display: inline-block;
  @include media-breakpoint-down(lg) {
    margin-top: 0 !important;
  }
}

.visit_menu{
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 32px;
  margin-top: 100px;
  @include media-breakpoint-down(md) {
    margin-top: 32px;
    border-top: 0;
    padding-top: 0;
  }
}

.visit_box{
  background-color: rgb(244, 244, 244);
 padding: rem(16);

 h6{
    font-size: 20px;
  }

  address{
    font-size: 16px;
    margin-bottom: 12px;
  }

  .link{
    font-size: 16px;
    color: $black;
    text-decoration: underline;
  }
}

.home, .contact {
  .navbar-brand{
    @include media-breakpoint-down(lg) {
      margin: 0;
      -webkit-filter: none;
      filter: none;
      padding: 0;
    }
  }
}
