// fonts
@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");

// bootstrap & variables
@import "~bootstrap/scss/functions";
@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "utilities";
@import "user";

// components
@import "components/_index";
