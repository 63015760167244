//
// forms
//

.form-switch {
  .form-check-input {
    border-radius: rem(100);
  }
}

// form switch right
.form-switch-right {
  position: relative;
  width: 100%;
  margin: 0;
  padding-left: 0;
  padding-right: rem(56);

  &:hover {
    .form-check-label {
      opacity: 0.75;
    }
  }

  .form-check-label {
    @include transition;
    width: 100%;
    opacity: 0.5;
    cursor: pointer;
    color: $black;
  }

  .form-check-input {
    position: absolute;
    right: 0;
    cursor: pointer;
  }

  .form-check-input:checked ~ .form-check-label {
    opacity: 1;
  }
}

// minimal checkbox
.form-check-minimal {
  padding: 0;

  .form-check-input {
    display: none;
  }
  

  .form-check-input:checked ~ .form-check-label {
    padding-left: rem(8);
    padding-right: rem(40);
    border-color: $border-color;

    &::before {
      opacity: 1;
    }
  }

  .form-check-label {
    display: inline-flex;
    position: relative;
    transition: all 0.2s;
    cursor: pointer;
    padding: rem(4) rem(8) rem(4) 0;
    line-height: rem(24);
    border: $border-width solid transparent;

    &::before {
      transition: all 0.2s;
      position: absolute;
      top: 0;
      right: 0;
      width: rem(32);
      height: rem(32);
      line-height: rem(32);
      font-family: bootstrap-icons !important;
      font-size: $font-size-base;
      text-align: center;
      content: "\f62a";
      background: $border-color;
      opacity: 0;
    }

    &:hover {
      color: $primary;
    }
  }
}

// color checkbox
.form-check-color {
  padding: 0;

  .form-check-input {
    display: none;
  }

  .form-check-input:checked ~ .form-check-label {
    padding-left: rem(8);
    padding-right: rem(40);
    border-color: $border-color;

    &::before {
      opacity: 1;
    }
  }

  .form-check-label {
    display: inline-flex;
    align-items: center;
    position: relative;
    transition: all 0.2s;
    cursor: pointer;
    padding: rem(4) rem(8) rem(4) 0;
    line-height: rem(24);
    border: $border-width solid transparent;

    &::before {
      transition: all 0.2s;
      position: absolute;
      top: 0;
      right: 0;
      content: "";
      width: rem(32);
      height: rem(32);
      line-height: rem(32);
      font-family: bootstrap-icons !important;
      font-size: $font-size-base;
      text-align: center;
      content: "\f62a";
      background: $border-color;
      opacity: 0;
    }

    &:hover {
      color: $primary;
    }

    span {
      display: inline-flex;
      width: rem(16);
      height: rem(16);
      margin-right: rem(8);
      border-radius: 50%;
    }
  }
}

.contact_form{

  @include media-breakpoint-down(lg) {
    padding-top: 80px !important;
  }
  label{
    color: $black !important;

    a{
      color: $black !important;
    }
  }

  .btn-primary{
    background:$purple-1;
    height: 54px;
    border-radius:12px 0px 12px 0px;
    padding:0 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: $white-1;
    margin-right: 18px;
    @include transition;
    margin-top: 0;
    max-width: 150px;
    border-color:$purple-1 ;

    &:hover{
      background: $pink;
      border-color:$pink;
    }
  }

  .container{
    @include media-breakpoint-down(lg) {
      max-width: 100% !important;
    }
  }

  .contact_left{
    @include media-breakpoint-down(md) {
      margin-bottom: 80px !important;
    }
    hr{
      @include media-breakpoint-down(md) {
        display: none;
        
      }
    }
  }
}
