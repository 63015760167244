//
// buttons
//

// colors
@each $color, $value in $colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

// text + icon btn
.btn-with-icon {
  position: relative;
  padding-right: $btn-padding-x * 2;
  text-align: left;

  .bi {
    @include translate(0, -50%);
    position: absolute;
    top: 50%;
    right: $btn-padding-x;
  }

  &.btn-sm {
    padding-right: $btn-padding-x-sm * 2;

    .bi {
      right: $btn-padding-x-sm;
    }
  }

  &.btn-lg {
    padding-right: $btn-padding-x-lg * 2;

    .bi {
      right: $btn-padding-x-lg;
    }
  }
}

// icon btn
.btn-icon {
  position: relative;
  box-sizing: content-box;
  width: 1.5em;
  height: 1.5em;
  padding: $btn-padding-y;

  &[class*="-sm"] {
    padding: $btn-padding-y-sm;
  }

  &[class*="-lg"] {
    padding: $btn-padding-y-lg;
  }

  &[class*="-xl"] {
    padding: $btn-padding-y-lg * 1.5;
    font-size: rem(24);
  }

  .bi {
    @include translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.btn img {
  width: auto;
  height: rem(32);
}

.btn-white:hover {
  background-color: $gray-100;
}

.btn-outline-light {
  color: $black;
}

.btn-filter {
  @include nofocus;
  display: inline-block;
  border: $border-width solid $border-color;
  color: $secondary;

  &:not(:last-child) {
    margin-right: rem(4);
  }

  &:hover {
    border-color: $black;
  }

  &.current {
    background-color: $black;
    border-color: $black;
    color: $white;
  }
}


.nav_btn{
  display: flex;
  margin-top: 40px;
  @include media-breakpoint-down(lg) {
    margin-top: 50px;
  }

  .btn-primary{
    background:$purple-1;
    height: 54px;
    border-radius:12px 0px 12px 0px;
    padding:0 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: $white-1;
    margin-right: 18px;
    @include transition;
    margin-top: 0;
    @include media-breakpoint-down(xl) {
      font-size: 16px;
      padding: 0 28px;
    }
    @include media-breakpoint-down(md) {
      width: 50%;
      font-size: 15px;
      padding: 0 20px;
    }

    &:hover{
      background: $pink;
    }
    
  }
  
  .btn_border{
    border: 2px solid $pink;
    height: 54px;
    border-radius:12px 0px 12px 0px;
    padding:0 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: $pink;
    margin: 0;
    @include media-breakpoint-down(xl) {
      font-size: 16px;
      padding: 0 28px;
    }
    @include media-breakpoint-down(md) {
      width: 50%;
      font-size: 15px;
      padding: 0 20px;
    }
    @include transition;
    &:hover{
      background: $purple-1;
      color: $white-1;
      border-color:$purple-1;
    }
  }
}

.nav_btn_index{
  display: flex;
  margin-top: 30px;
  margin-bottom: 10px;
  @include media-breakpoint-down(lg) {
    margin-top: 0px;
  }

  .btn-primary{
    background:$purple-1;
    height: 54px;
    border-radius:12px 0px 12px 0px;
    padding:0 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: $white-1;
    margin-right: 18px;
    @include transition;
    margin-top: 0;
    @include media-breakpoint-down(xl) {
      font-size: 16px;
      padding: 0 28px;
    }
    @include media-breakpoint-down(md) {
      width: 50%;
      font-size: 15px;
      padding: 0 20px;
    }

    &:hover{
      background: $pink;
    }
    
  }
  
  .btn_border{
    border: 2px solid $pink;
    height: 54px;
    border-radius:12px 0px 12px 0px;
    padding:0 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: $pink;
    margin: 0;
    @include media-breakpoint-down(xl) {
      font-size: 16px;
      padding: 0 28px;
    }
    @include media-breakpoint-down(md) {
      width: 50%;
      font-size: 15px;
      padding: 0 20px;
    }
    @include transition;
    &:hover{
      background: $purple-1;
      color: $white-1;
      border-color:$purple-1;
    }
  }
}


