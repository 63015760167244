


footer{
    width: 100%;
    padding-top: 50px;

    .container{
        max-width: 1400px;
        margin: 0 auto;
        padding: 0 20px;
        @include media-breakpoint-down(xxl) {
            padding: 0 80px;
        }

        @include media-breakpoint-down(md) {
            padding: 0;
        }

        
    }

    .explore_bg{
        background: $purple-1;
        padding: 60px;
        display: flex;
        align-items: center;
        @include media-breakpoint-down(md) {
            margin: 0 16px;
            padding: 24px 0;
        }

       

        .explore_desc{
            max-width: 472px;
        }

        .explore_left{
          

            h2{
                color: $white;
            }

            p{
                color: $white;
                font-size: 18px;
                line-height: 27px;
                font-weight: 400;
            }

            .explore_btn {
                display: flex;
                align-items: center;
                margin-top: 32px;
                .white_btn{
                    background: $white;
                    border-radius: 12px 0 12px 0;
                    height: 54px;
                    display: inline-block;
                    padding: 0 20px;
                    max-width: 179px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $purple-1;
                    margin-right:18px;
                    font-size: 18px;
                    font-weight: normal;

                    &:hover{
                        background: $pink;
                        color: $white;
                    }
                }

                .link_btn{
                    color: $white;
                    text-decoration: underline;
                    &:hover{
                        color:$pink;
                    }
                }
            }
        }

        .explore_right{
          

            .form-row {
                display: flex;
                margin: 0 -15px 15px;

                @include media-breakpoint-down(md) {
                    flex-direction: column;
                    margin: 0 -15px 0;
                }

                .col-md-6{
                    padding: 0 15px;
                }

            }

            
            label{
                color: $white;
                margin-bottom: 5px;
            }

            .form-control{
                background: transparent;
                border: 1px solid $white;
                width: 100%;
                height: 54px;
                padding:0 16px;
                color: $white;

                &::-ms-input-placeholder { 
                    color: $white;
                }
                  
                &::placeholder {
                    color: $white;
                }
            }

            .form-check-input{
                width: 15px;
                height: 15px;
                padding: 0;
            }

            .form-group{
                margin-bottom: 15px;
                @include media-breakpoint-down(md) {
                    margin-bottom: 24px;
                }
            }

            .submit_btn{
                background: transparent;
                border-radius: 12px 0 12px 0;
                height: 54px;
                display: inline-block;
                padding: 0 32px;
                max-width: 179px;
                border:2px solid $white;
                &:hover{
                    background: $pink;
                    color: $white;
                    border-color: $pink;
                }
            }
        }
    }

    .footer_main {
        display: flex;
        justify-content: space-between;
        padding: 60px 0 32px;
        @include media-breakpoint-down(md) {
            flex-direction: column;
            padding: 60px 32px 32px;
        }

        .footer_logo{
            margin-bottom: 24px;
        }

        .phone{
            color: $black;
            margin-bottom: 10px;
            a{
                color: $black;
            }
        }

        .footer_left{
            @include media-breakpoint-down(md) {
                margin-bottom: 32px;
            }
        }

        .footer_right{
            display: flex;

            .footer_box{
                margin-right: 40px;

                &:last-child{
                    margin-right: 0;
                }

                .title{
                    font-weight: 500;
                    font-size: 20px;
                    margin-bottom: 12px;
                    display: inline-block;
                }
            }

            ul{
                margin: 0;
                padding: 0;

                li{
                    list-style: none;
                    margin-bottom: 8px;
                    &:last-child{
                        margin-bottom: 0;
                    }

                    a{
                        color: $black;
                    }
                }
            }
        }
        
    }

    .copy{
        border-top: 1px solid rgba(0,0,0,0.2);
        padding: 32px 0;
        text-align: center;
        @include media-breakpoint-down(md) {
            padding: 32px 15px 24px;
            font-size: 14px;
        }
    }
}